@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 
    'Manrope',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 20px;
}
.ant-carousel {
  .slick-next {
    &::after {
      display: none;
    }
  }
  .slick-prev { 
    &::after {
      display: none;
    }
  }
}
.margin-arrow{
  .slick-next {
    margin-right: -75px;
  }
  .slick-prev { 
    margin-left: -75px;
  }
}

.margin-arrow-sm{
  .slick-next {
    margin-right: -38px;
  }
  .slick-prev { 
    margin-left: -38px;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
